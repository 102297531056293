import tour1 from "../img/tour1.jpg";
import tour2 from "../img/tour2.jpg";
import tour3 from "../img/tour3.jpg";

export const tours = [
  {
    id: "1",
    title: "Uzbekistan City Tour",
    image: tour1, // Add URLs for images here
    status: "Available",
    duration: "6 days / 5 nights",
    included: [
      "Airport/Hotel pick-up-drop offs",
      "Transport access(car, bus, van)",
      "Professional guidance service",
      "4-5 Star Hotel Accommodation",
      "Trains normal/high speed",
      "Entrance Fee for Touristic Sites",
      "Complimentary City Maps",
      "Multilingual Support Guides",
      "Free Wi-Fi Access in Hotels",
      "Bottled Water During Tours",
    ],
    notIncluded: [
      "Charges in Hotels for Additional Services",
      "Personal Expenses",
      "Meals (Lunch/Dinner)",
      "Tourist Visa Fee",
      "Travel Insurance",
      "International Fly Tickets to/from Uzbekistan",
      "Photo Session",
      "Tips for Guides and Drivers",
      "Recreational Activities",
    ],
    originalPrice: "385",
    discount: "50",
    finalPrice: "335", // Reflecting the price after the discount
    description: `<p>
  Experience a captivating journey through Uzbekistan, visiting its most iconic cities and landmarks.
  Start in 
  <a href="https://en.wikipedia.org/wiki/Tashkent" target="_blank" class="text-blue-500 hover:underline">
    Tashkent
  </a>, the vibrant capital known for its stunning blend of modern and Soviet-era architecture.
  Travel to the ancient city of 
  <a href="https://en.wikipedia.org/wiki/Samarkand" target="_blank" class="text-blue-500 hover:underline">
    Samarkand
  </a>, a UNESCO World Heritage Site famous for the majestic Registan Square.
  Explore 
  <a href="https://en.wikipedia.org/wiki/Bukhara" target="_blank" class="text-blue-500 hover:underline">
    Bukhara
  </a>, a historical center of culture and religion with over 140 architectural monuments.
  This tour offers a perfect mix of cultural exploration and relaxation, ensuring a memorable experience in Uzbekistan.
</p>
`,
    advantages: [
      "Small group size for personalized experiences",
      "All-inclusive package (meals, transport, accommodations)",
      "Local expert guides with in-depth knowledge",
      "Flexible itinerary with free time to explore",
      "Great value for money with premium services",
    ],
    reviews: 4.8, // Average customer reviews (out of 5)
    availableDates: ["2024-05-10", "2024-06-15", "2024-07-20"], // Available dates for the tour
    languagesOffered: ["English", "Russian", "Spanish"], // Languages the tour is offered in
    maxGroupSize: "1-12", // Maximum number of participants
    cancellationPolicy: "Free cancellation up to 7 days before the tour", // Cancellation policy
    tourPlan: [
      {
        day: 1,
        title: "Arrival in Tashkent",
        activities: [
          "Arrive in Tashkent and check into your hotel.",
          "Visit Chorsu Bazaar for local shopping.",
          "Explore Kukaldosh Madrasah.",
          "Visit Khast Imam Complex to see the original copy of the Quran.",
          "Enjoy Tashkent Plov at Bashkozon and witness the cooking process.",
          "Stroll through Memorial Square.",
          "Experience the vibrant night scene in Tashkent.",
        ],
      },
      {
        day: 2,
        title: "Tashkent to Samarkand",
        activities: [
          "Depart for Samarkand by train in the early morning.",
          "Check into your hotel upon arrival.",
          "Discover Gur-e-Amir Mausoleum and Registan Square.",
          "Enjoy dinner with traditional dancing at a local restaurant.",
        ],
      },
      {
        day: 3,
        title: "Exploring Samarkand",
        activities: [
          "Visit Siyab Bazaar and experience local life.",
          "Tour Bibi-Khanym Mosque.",
          "Visit Konigil Paper Factory to see natural paper-making.",
          "Explore pottery workshops and the Ulugh Beg Observatory.",
        ],
      },
      {
        day: 4,
        title: "Samarkand to Bukhara",
        activities: [
          "Travel to Bukhara by train or van.",
          "Check into your hotel upon arrival.",
          "Explore Ark Fortress and visit Chor Minor.",
          "Enjoy lunch at a local restaurant and stroll through Bukhara.",
        ],
      },
      {
        day: 5,
        title: "Full Day in Bukhara",
        activities: [
          "Visit Sitorai Mohi Xosa, the summer palace.",
          "Explore Zindon prison.",
          "Visit Labi-Hovuz complex for relaxation.",
          "Experience the magical atmosphere of Bukhara at night.",
        ],
      },
      {
        day: 6,
        title: "Departure from Bukhara to Tashkent",
        activities: [
          "Check out from your hotel and enjoy breakfast.",
          "Travel back to Tashkent for your departure.",
        ],
      },
    ],
  },
  {
    id: "2",
    title: "Individual Cultural Tour",
    image: tour2, // Add URLs for images here
    status: "Available",
    duration: "5 days / 4 nights",
    included: [
      "4-star hotels (2 nights in Tashkent, 2 nights in Samarkand) with breakfast",
      "Professional guide",
      "Private car for all transfers",
      "High-speed train tickets",
      "Special gifts from us",
    ],
    notIncluded: [
      "Personal expenses",
      "International flights",
      "Visas",
      "Train tickets (assistance for booking)",
      "Entrance fees",
      "Additional hotel services",
      "Tips for guides and drivers",
    ],
    originalPrice: "750",
    discount: "100",
    finalPrice: "650", // Reflecting the price after the discount
    description: `<p>
      Experience a unique cultural journey through Uzbekistan.
      Explore Tashkent’s historical sites, including the famous Khast-Imam Complex and Chorsu Bazaar.
      Discover the beauty of the Chimgan Mountains and Amirsoy Resort.
      Travel to Samarkand on a high-speed train and visit iconic landmarks such as Registan Square and Gur-e-Amir Mausoleum.
      Enjoy optional experiences like pottery classes and visiting a local Uzbek family.
    </p>`,
    advantages: [
      "Small group size for personalized experience",
      "All-inclusive package with transport and accommodations",
      "Expert local guides",
      "Flexible itinerary with optional experiences",
      "Great value for money with premium services",
    ],
    reviews: 4.8, // Average customer reviews (out of 5)
    availableDates: ["2024-05-10", "2024-06-15", "2024-07-20"],
    languagesOffered: [
      "English",
      "Russian",
      "Spanish",
      "German",
      "Turkish",
      "Arabic",
    ],
    maxGroupSize: "1-2", // Maximum number of participants
    cancellationPolicy: "Free cancellation up to 7 days before the tour",
    tourPlan: [
      {
        day: 1,
        title: "Tashkent City Tour",
        activities: [
          "Khast-Imam Complex – Visit the famous Quran of Caliph Uthman.",
          "Chorsu Bazaar – Experience the traditional market.",
          "Amir Temur Square & Museum.",
          "Tashkent Metro Tour – Explore beautifully decorated metro stations.",
          "Independence Square & Memorial Square.",
          "Lunch at Beshkazan.",
          "Magic City & Tashkent City Park.",
        ],
      },
      {
        day: 2,
        title: "Amirsoy Resort & Chimgan Mountains",
        activities: [
          "Amirsoy Resort – Cable car ride, skiing (if in winter), hiking.",
          "Chimgan Mountains – Enjoy nature and fresh air.",
          "Chorvoq Lake & Charvak Reservoir Viewpoint.",
        ],
      },
      {
        day: 3,
        title: "Tashkent to Samarkand by High-Speed Train & City Tour",
        activities: [
          "Morning train to Samarkand.",
          "Registan Square – The most iconic place in Samarkand.",
          "Gur-e-Amir Mausoleum.",
          "Bibi-Khanym Mosque.",
          "Siyob Bazaar.",
          "Evening sightseeing of Eternal Samarkand City.",
        ],
      },
      {
        day: 4,
        title: "More Samarkand Sightseeing",
        activities: [
          "Shakhi-Zinda Necropolis.",
          "Afrosiab Museum & Ancient Ruins.",
          "Konigil Paper Mill.",
          "Ulugbek Observatory.",
          "Optional: Wine Tasting at Hovrenko Winery, Pottery Class, Master Class with a Local Family.",
        ],
      },
      {
        day: 5,
        title: "Return to Tashkent by High-Speed Train",
        activities: [
          "Morning train back to Tashkent.",
          "Free time for shopping at Samarkand Darvoza or Mega Planet Mall before departure.",
        ],
      },
    ],
  },
  {
    id: "3",
    title: "Tashkent Mountain Tour: Amirsoy, Chimgan, Chorvoq & Deer Hills",
    image: tour3, // Replace with actual image URL
    status: "Available",
    duration: "Full Day",
    included: [
      "Comfortable transport (Car, SUV, Minivan, or Bus)",
      "Professional Guide in multiple languages",
      "Hotel pick-up & drop-off",
    ],
    notIncluded: [
      "Personal expenses",
      "Cable car, ski passes, or extra activities",
      "Meals (but recommendations will be provided!)",
    ],
    finalPrice: "50",
    originalPrice: "75",
    groupDiscount: "$100 for 3-4 people",
    description: `A full-day adventure exploring the stunning mountain landscapes near Tashkent. Enjoy skiing, hiking, boating, and wildlife encounters!`,
    advantages: [
      "Affordable & Best Price Guarantee – 50% cheaper than competitors!",
      "Exclusive Deer Hills Experience – Unique & not found on other tours!",
      "Perfect for All Travelers – Families, friends, solo adventurers!",
      "Fast & Easy Booking – No deposit needed, pay on arrival!",
    ],
    reviews: 4.8, // Average customer review rating
    availableDates: [
      "2025-02-02 to 2025-02-28", // Winter season
      "2025-03-01 to 2025-11-30", // Spring-Autumn season
    ],
    languagesOffered: [
      "English",
      "German",
      "Korean",
      "Russian",
      "Chinese",
      "Spanish",
    ],
    maxGroupSize: "1-30",
    cancellationPolicy: "Free cancellation up to 48 hours before the tour",
    tourPlan: [
      {
        day: 1,
        title: "Pick-up from Tashkent",
        activities: [
          "Start your day with a comfortable ride from your hotel.",
          "Enjoy the changing landscapes as we leave the city and head towards the breathtaking mountains.",
        ],
      },
      {
        day: 2,
        title: "Amirsoy Resort – The Winter Wonderland",
        activities: [
          "Experience skiing, snowboarding, and tubing (optional).",
          "Take a cable car ride for stunning panoramic views.",
          "Explore cozy cafés and enjoy a cup of hot tea in the mountains.",
        ],
      },
      {
        day: 3,
        title: "Chimgan Mountains – The Roof of Tashkent",
        activities: [
          "Take a hiking trip or enjoy the fresh mountain air.",
          "Try horseback riding (optional).",
          "Capture breathtaking photos of the snow-covered peaks.",
        ],
      },
      {
        day: 4,
        title: "Chorvoq Lake – The Pearl of the Mountains",
        activities: [
          "Walk along the lake’s scenic shoreline.",
          "Take incredible photographs of the turquoise water and surrounding landscapes.",
          "(Optional) Try jet skiing, parasailing, or a boat ride (summer only).",
        ],
      },
      {
        day: 5,
        title: "Deer Hills – A Hidden Gem",
        activities: [
          "Meet and feed wild deer in a peaceful natural setting.",
          "Enjoy a relaxing break in a panoramic viewpoint.",
          "Learn about the local wildlife and ecology.",
        ],
      },
      {
        day: 6,
        title: "Return to Tashkent",
        activities: [
          "After a day filled with adventure and nature, we will drive back to your hotel.",
        ],
      },
    ],
  },
];
