import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import toursTop from "../../img/toursTop.png";
import TourCard from "../../components/cards/TourCard";
import { tours } from "../../utils/toursDb";

const Tours = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-col justify-between">
      <Header />
      <main className="mt-20 flex flex-col gap-20">
        <div
          style={{ backgroundImage: `url(${toursTop})` }}
          className="flex h-[350px] w-full bg-cover bg-center bg-no-repeat"
        ></div>
        <div className="container grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {tours.map((tour) => (
            <TourCard key={tour.id} tour={tour} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Tours;
