import React from "react";
import { FooterLocation, FooterMail, FooterPhone } from "../../img/svgs";
import {
  FaInstagram,
  FaTelegramPlane,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

import Logo from "../../img/logo.png";

const Footer = () => {
  return (
    <footer className="mt-28 flex w-full flex-col bg-black">
      <div className="container flex flex-col items-start justify-between px-4 py-12 md:flex-row md:px-8 md:py-28 lg:px-16">
        <div className="flex w-full max-w-[258px] flex-col">
          <img src={Logo} alt="" />
          <span className="mt-5 text-2xl font-semibold capitalize leading-[32px] text-white md:text-3xl md:leading-[48px]">
            Want to Take Tour Packages?
          </span>
          <button className="mt-6 flex items-center justify-center rounded-[5px] bg-[#63ab45]">
            <NavLink
              to={"/contact"}
              className="px-6 py-3 text-base font-semibold capitalize leading-none tracking-wide text-white md:pb-[15.50px] md:pl-[30px] md:pr-[29.65px] md:pt-[14.50px]"
            >
              Book A Tour
            </NavLink>
          </button>
        </div>

        <div className="mt-8 flex flex-col gap-5 md:mt-0">
          <span className="text-lg font-semibold leading-tight tracking-wide text-white/80 md:text-xl">
            Quick Link
          </span>
          <ul className="flex flex-col gap-2">
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <NavLink
                to={"/about"}
                className="text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                About Us
              </NavLink>
            </li>
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <NavLink
                to={"/tours"}
                className="text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                Our Tours
              </NavLink>
            </li>
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <NavLink
                to={"/clients"}
                className="text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                Happy Clients
              </NavLink>
            </li>
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <NavLink
                to={"/contact"}
                className="text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="mt-8 flex w-full max-w-[258px] flex-col gap-7 md:mt-0">
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <FooterPhone />
              <span className="text-lg font-semibold leading-tight tracking-wide text-white/80 md:text-xl">
                More Inquiry
              </span>
            </div>
            <span className="text-base font-normal leading-[30px] tracking-wide text-white/60">
              <a href="tel:+998(88)111-85-32">+998(88)111-85-32</a>
            </span>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <FooterMail />
              <span className="text-lg font-semibold leading-tight tracking-wide text-white/80 md:text-xl">
                Send Mail
              </span>
            </div>
            <span className="text-base font-normal leading-[30px] tracking-wide text-white/60">
              <a href="mailto:ergashevadilnura632@gmail.com">
                ergashevadilnura632@gmail.com
              </a>
            </span>
          </div>

          <div className="hidden flex-col gap-3">
            <div className="flex items-center gap-2">
              <FooterLocation />
              <span className="text-lg font-semibold leading-tight tracking-wide text-white/80 md:text-xl">
                Address
              </span>
            </div>
            <span className="text-base font-normal leading-[30px] tracking-wide text-white/60">
              House 168/170, Avenue 01, Mirpur DOHS, Dhaka Bangladesh
            </span>
          </div>
        </div>

        <div className="mt-8 flex w-full max-w-[300px] flex-col gap-5 md:mt-0">
          <span className="text-lg font-semibold leading-tight tracking-wide text-white/80 md:text-xl">
            We Are Here
          </span>
          <ul className="flex flex-col gap-2">
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <a
                href="https://wa.me/998881118532"
                className="flex items-center gap-1 text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                <FaWhatsapp className="text-xl" />
                WhatsApp
              </a>
            </li>
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <a
                href="https://instagram.com/guidetour.uzbekistan"
                className="flex items-center gap-1 text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                <FaInstagram className="text-xl" />
                Instagram
              </a>
            </li>
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <a
                href="https://youtube.com/guidetour.uzbekistan"
                className="flex items-center gap-1 text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                <FaYoutube className="text-xl" />
                YouTube
              </a>
            </li>
            <li className="flex items-center justify-start pb-[7.50px] pr-[42.37px] pt-[6.50px]">
              <a
                href="https://t.me/guideuzbekistantravelling"
                className="flex items-center gap-1 text-base font-normal leading-none tracking-tight text-[#ffffff99] transition hover:text-white"
              >
                <FaTelegramPlane className="text-xl" />
                Telegram
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container flex flex-col items-center justify-between border-t border-[#ffffff1a] py-5 md:flex-row">
        <div className="flex w-full items-center justify-center gap-1 md:justify-start">
          <span className="text-sm font-light tracking-wide text-white/50">
            ©Copyright 2024 | Developed by
          </span>
          <a
            href="https://www.giyozov.uz/"
            target="_blank"
            rel="noreferrer"
            className="text-sm font-light tracking-wide text-white/50 underline"
          >
            giyozov.uz
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
