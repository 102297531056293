import React from "react";

import tour1 from "../../img/tours1.png";
import { TourCardCalendar, TourCarLocation } from "../../img/svgs";
import { Link } from "react-router-dom";

const TourCard = ({ tour }) => {
  return (
    <Link
      to={`/tours/${tour.id}`}
      className="flex shrink-0 flex-col rounded-[10px] bg-white shadow-lg"
    >
      <div className="flex flex-col">
        <div className="relative flex w-full p-4">
          <img
            className="h-[265px] w-full max-w-[395px] rounded-lg"
            src={tour.image}
            alt="Tour"
          />
          <div className="absolute left-4 top-4 flex items-center justify-center gap-[7px] rounded-br-[5px] rounded-tl-[5px] border border-[#63ab4580] bg-white p-2">
            <TourCarLocation />
            <span className="text-xs font-normal uppercase leading-3 tracking-wide text-[#100c08]">
              Uzbekistan
            </span>
          </div>
        </div>
        <span className="p-6 text-[22px] font-medium capitalize leading-[30.80px] text-[#100c08]">
          {tour.title}
        </span>
      </div>

      <div className="flex items-center justify-between border-t border-[#100c081a] p-6">
        <div className="flex flex-col gap-0.5">
          <span className="text-sm font-medium capitalize leading-[14px] tracking-tight text-[#100c08]">
            Starting From:
          </span>
          <div className="flex items-center gap-1">
            <span className="text-[22px] font-extrabold capitalize leading-snug tracking-tight text-[#63ab45]">
              ${tour.finalPrice}
            </span>
            <span className="text-base font-medium capitalize leading-none tracking-tight text-[#100c08]/50 line-through">
              ${tour.originalPrice}
            </span>
          </div>
          <span className="text-[11px] font-normal capitalize leading-[11px] tracking-tight text-[#100c08]">
            TAXES INCL/PERS
          </span>
        </div>

        <div className="flex items-center gap-0.5">
          <TourCardCalendar />
          <span className="text-sm font-medium capitalize leading-[14px] text-[#100c0899]">
            {tour.duration}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default TourCard;
